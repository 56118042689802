@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: #3B82F6;
    --color-secondary: #10B981;
    --color-accent: #F59E0B;
    --color-text-base: #1F2937;
    --color-text-muted: #6B7280;
    --color-bg-base: #F9FAFB;
    --color-bg-alt: #E5E7EB;
  }

  h1 {
    @apply text-3xl font-bold tracking-tight text-gray-900 md:text-5xl mb-6;
  }

  h2 {
    @apply text-2xl font-semibold tracking-tight text-gray-900 md:text-4xl mb-6;
  }

  h3 {
    @apply text-2xl font-semibold tracking-tight text-gray-900 md:text-3xl mb-4;
  }

  h4 {
    @apply text-xl font-medium tracking-tight text-gray-900 sm:text-2xl mb-4;
  }

  h5 {
    @apply text-lg font-medium tracking-tight text-gray-900 sm:text-lg;
  }

  p {
    @apply text-base text-gray-800;
  }

  a {
    @apply text-blue-600 hover:text-blue-800 outline-none;
  }

  body {
    @apply bg-gray-50 text-gray-900 antialiased;
  }
}

@layer components {
  .btn {
    @apply inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500;
  }

  .input {
    @apply block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm;
  }
}

@layer utilities {
  .text-gradient {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  }

  .bg-gradient {
    background-image: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  }
}
